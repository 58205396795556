import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import useLanguage from '../states/context/LanguageContext'
import useInput from '../utils/hooks/useInput'
import useAddressAutocomplete from '../utils/hooks/useAddressAutocomplete'
import useOnClickOutisde from '../utils/hooks/useOnClickOutside'
import handleSubmit from '../utils/functions/handleSubmit'

import Input from './input'
import FormButton from './button'
import InputOptions from './inputOptions'

enum Status {
  isLoading = "isLoading",
  isResolved = "isResolved",
  isRejected = "isRejected",
  isIdle = "isIdle"
}

function Form() {
  const { language } = useLanguage()
  const [step, setStep] = useState(1)
  const [status, setStatus] = useState(Status.isIdle)
  const [focused, setFocused] = useState(false)
  const ref = useRef()
  const [showSuggestions, setShowSuggestions] = useState(false);
  useOnClickOutisde(ref, () => setShowSuggestions(false))
  const name = useInput("")
  const email = useInput("")
  const phone = useInput("")
  const { value: address, bind, setValue, suggestions, setSuggestions } = useAddressAutocomplete("")
  const option = useInput("")
  const isFrench = language === 'fr'

  useEffect(() => {
    var addressInput = document.getElementById('address-input')

    if (addressInput) {
      addressInput.addEventListener("focusin", () => {
        setFocused(true)
      });
      addressInput.addEventListener("focusout", () => {
        setFocused(false)
      });
    }

    return () => {
      document.removeEventListener("focusin", () => { })
      document.removeEventListener("focusout", () => { })
      setFocused(false)
    }
  }, [])

  useEffect(() => {
    setShowSuggestions(false)

    if (suggestions?.length > 0) setShowSuggestions(true)

    return () => setShowSuggestions(false)
  }, [suggestions])

  return (
    <Wrapper>
      <FormWrapper onSubmit={() => {
        setStatus(Status.isLoading)
        handleSubmit({ name: name.value, email: email.value, phone: phone.value, address, option: option.value, isFrench }).then((response) => {
          if (response == "Error...") {
            setStatus(Status.isRejected)
          }
        })
      }
      }>
        {step === 1 &&
          <>
            <AddressInputWrapper id="address-input-wrapper">
              <Input name={isFrench ? 'Adresse de la propriété' : 'Address of your property'} {...bind} icon={'/images/placeholder.png'} isTyping={focused && showSuggestions} id="address-input" />
              {showSuggestions && (
                <SuggestionWrapper ref={ref}>
                  {suggestions.map((suggestion, index) => {
                    return (
                      <Suggestion
                        key={index}
                        onClick={() => {
                          setValue(suggestion.place_name);
                          setSuggestions([]);
                        }}
                      >
                        {suggestion.place_name}
                      </Suggestion>
                    );
                  })}
                </SuggestionWrapper>
              )}
            </AddressInputWrapper>
            <FormButton text={isFrench ? 'Suivant' : 'Next'} type="button" onClick={() => { address === "" ? toast.error("Veuillez entrer une addresse valide.", { position: "top-center" }) : setStep(2) }} />
          </>
        }
        {
          step === 2 &&
          <>
            <Input name={isFrench ? 'Nom complet' : 'Full name'} icon={'/images/user.png'} {...name} />
            <Input name={isFrench ? 'Adresse courriel' : 'Email address'} icon={'/images/email.png'} {...email} />
            <Input name={isFrench ? 'Numéro de téléphone' : 'Phone number'} icon={'/images/telephone.png'} {...phone} />
            <InputOptions name={isFrench ? 'Je voudrais vendre dans...' : 'I would like to sell...'} options={isFrench ? ['Dans les 3 prochains mois', 'Dans les 6 prochains mois', 'Dans les 12 prochains mois', `J'aimerais seulement avoir mon estimation`] : ['In the next 3 months', 'In the next 6 months', 'In the next 12 months', `I simply want my estimate`]} onChange={option.onChange} />
            <ButtonsWrapper>
              <FormButton text={isFrench ? 'Précédent' : 'Previous'} type="button" onClick={() => { setStep(1) }} />
              <FormButton text={isFrench ? 'Envoyer' : 'Send'} type="submit" isLoading={status == Status.isLoading} />
            </ButtonsWrapper>
          </>
        }
      </FormWrapper>
      <ToastContainer />
    </Wrapper>
  )
}

export default Form

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 30px;
  padding: 40px;
  width: 400px;

  @media (max-width: 550px) {
    width: 300px;
  }

  @media (max-width: 405px) {
    width: 250px;
  }

  @media (max-width: 372px) {
    width: 200px;
  }

  .Toastify__toast {
    background: white;
    color: rgb(27, 27, 27);
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
  }

  .Toastify__toast--success {
    .Toastify__progress-bar {
      background-color: rgb(14, 112, 55);
    }
  }

  .Toastify__toast--error {
    .Toastify__progress-bar {
        background-color: rgb(112, 14, 14);
    }
  }
`

const FormWrapper = styled.form`
  display: grid;
  justify-items: center;
  gap: 10px;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 100px;

  @media (max-width: 405px) {
    gap: 50px;
  }

  @media (max-width: 372px) {
    grid-template-columns: auto;
    gap: 0px;
    justify-items: center;
  }
`

const AddressInputWrapper = styled.div`
  position: relative;
  font-family: 'Raleway', sans-serif !important;
  font-size: 14px;
`

const SuggestionWrapper = styled.div`
  background: white;
  position: absolute;
  width: 320px;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid  #000;

  @media (max-width: 550px) {
    width: 225px;
  }

  @media (max-width: 372px) {
    width: 175px;
  }
`;

const Suggestion = styled.p`
  cursor: pointer;
  width: 250px;
  padding: 5px 20px;

  :hover {
    background: rgba(0,0,0,0.1);
  }

  @media (max-width: 550px) {
    width: 185px;
  }

  @media (max-width: 372px) {
    width: 135px;
  }
`;
