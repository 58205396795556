import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  type: "button" | "submit" | "reset"
  onClick?: () => any
  isLoading?: boolean
}

function FormButton(props: Props) {
  const { text, onClick, type, isLoading = false } = props

 return (
  <Wrapper onClick={onClick}>
    <Button type={type} disabled={isLoading}>{text}</Button>
  </Wrapper>
  )
}

export default FormButton

const Wrapper = styled.div`
  margin-top: 20px;
`

const Button = styled.button`
  background-color: #000;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;

  :disabled {
    background-color: #e6e4e4;
    color: rgba(0,0,0,0.4);
    cursor: default;
  }

  &:focus {
    outline: 0 !important;
  }

`