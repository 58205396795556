import React from "react"
import styled from "styled-components"

import useLanguage, { LanguageProvider } from '../states/context/LanguageContext'
import Form from "./form"

const HeroSection = () => {
  const { language, setEnglish, setFrench } = useLanguage()

  const handleLanguageChange = () => {
    language === 'fr' ? setEnglish() : setFrench()
  }
  return (
    <Wrapper>
      <LanguageButton onClick={handleLanguageChange}>{language === 'fr' ? "English" : "Français"}</LanguageButton>
      <ContentWrapper>
        <Logo src={'/images/full-logo-white.png'} />
        {language === 'fr' ? (
          <TextWrapper>
            <h1>
              Souhaitez-vous connaître la valeur de votre propriété ?
            </h1>
            <p>
              Remplissez ce court formulaire et le tour est joué !
            </p>
          </TextWrapper>
        ) : (
          <TextWrapper>
            <h1>
              Would you like to know the value of your property?
            </h1>
            <p>
              Fill in this short form and you'll know it!
            </p>
          </TextWrapper>
        )
        }
        <Form />
      </ContentWrapper>
    </Wrapper>
  )
}

const HeroSectionHOC = () => {
  return (
    <LanguageProvider>
      <HeroSection />
    </LanguageProvider>
  )
}
export default HeroSectionHOC

const Wrapper = styled.div`
  padding-bottom: 100px;
  position: relative;
  background-image: url('/images/banner.jpg');
  background-size: cover;
  background-position: center;
`

const LanguageButton = styled.p`
  color: white;
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 40px;
`

const ContentWrapper = styled.div`
  display: grid;
  justify-items: center;
  gap: 40px;
  padding: 80px 20px 0;
`

const Logo = styled.img`
  width: 300px;

  @media (max-width: 372px) {
    width: 250px;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  max-width: 450px;
  display: grid;
  gap: 10px;

  h1, p {
    color: white;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.5);
  }
`

// Banner 2021 origin: https://unsplash.com/photos/5Za2sS955yg
// Banner 2022 origin: https://unsplash.com/photos/QZqQTWhCdQA