import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string
  icon: string
  value: string
  onChange: (event: any) => void
  isTyping?: boolean
  id?: string
}

interface StyledProps {
  isTyping: boolean
}
function Input(props: Props) {
  const { name, icon, value, onChange, isTyping = false, id = "" } = props


  return (
    <Wrapper>
      <InputWrapper placeholder={name} required onChange={onChange} value={value} isTyping={isTyping} id={id} />
      <Icon src={icon} alt='input icon' />
    </Wrapper>
  )
}

export default Input

const Wrapper = styled.div`
  position: relative;
`

const InputWrapper = styled.input<StyledProps>`
  border: solid 1px #000;
  padding: 10px 10px 10px 35px;
  border-radius: 20px;
  width: 300px;
  font-family: 'Raleway', sans-serif;

  &:focus {
    outline: 0 !important;
    border: 1px solid  #000;
    border-bottom: ${(props) => props.isTyping && "none"};
    border-radius: ${(props) => props.isTyping && "20px 20px 0px 0px"};
  }

  @media (max-width: 550px) {
    width: 200px;
  }

  @media (max-width: 372px) {
    width: 150px;
  }
`

const Icon = styled.img`
  position: absolute;
  width: 20px;
  left: 10px;
  top: 8px;
`