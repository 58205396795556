import React from "react"
import styled from "styled-components"

import "./layout.css"
import HeroSectionHOC from "./heroSection"

interface Props {
  children: React.ReactNode
}

const Layout = (props: Props) => {
  const { children } = props

  return (
    <div>
      <HeroSectionHOC />
      <Wrapper>
        <main>{children}</main>
      </Wrapper>
      <Footer>
        <SocialLinks>
          <a href="tel:514-451-7779">
            514-451-7779
          </a>
          <SocialMediaLinks>
            <img src={'/images/globe.png'} onClick={() => window.open('https://mililim.ca/')}/>
            <img src={'/images/instagram.png'} onClick={() => window.open('https://www.instagram.com/mililimremax/')}/>
            <img src={'/images/facebook.png'} onClick={() => window.open('https://www.facebook.com/MililimCourtierImmobilier')}/>
          </SocialMediaLinks>
        </SocialLinks>
        <p>
        © {new Date().getFullYear()} <a href="http://estimationsansfrais.com">estimationsansfrais.com</a>
        </p>
      </Footer>
    </div>
  )
}

export default Layout

const Wrapper = styled.div`
  padding: 40px;
`
const Footer = styled.div`
  background: #000;
  padding: 40px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  a, p {
    color: white;
    text-align: center;
  }

  a:hover {
    color: #c9c1c1;
  }

  @media (max-width: 416px) {
    grid-template-columns: auto;
    gap: 10px;
    justify-content: center;
  }
`

const SocialLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 10px;
  img {
    width: 20px;
    cursor: pointer;
  }

  @media (max-width: 520px) {
    grid-template-columns: auto;
  }
`

const SocialMediaLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 10px;

  @media (max-width: 416px) {
    justify-items: center;
  }
`