import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string
  options: string[]
  onChange: (event: any) => void
}

function InputOptions(props: Props) {
  const { name, options, onChange } = props
  
 return (
  <Wrapper key={`${name}-wrapper`}>
    <p key={`${name}-ptag`}>{name}</p>
      {options.map((option: string, index) => 
        (<div key={`div-${index}`}>
          <input key={`${option}${index}`} id={`option${index}`} type="radio" value={option.toLowerCase()} name="selling-options" required onChange={onChange}/>
          <label key={`${option}${index}-label`} htmlFor={`option${index}`}>
            {option}
          </label>
        </div>)
      )}
  </Wrapper>
  )
}

export default InputOptions

const Wrapper = styled.div`

  p {
    margin-bottom: 10px;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid rgb(27, 27, 27);
  }

  input[type='radio']:checked:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: #000;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid white;
  }

  label {
    font-size: 14px;
    padding-left: 10px;
  }

  @media (max-width: 372px) {
    width: 200px;
  }
`
