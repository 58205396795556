import 'firebase/firestore'
import getFirebase from '../firebase'
import emailjs from 'emailjs-com'
import { toast } from 'react-toastify'
import config from '../config'

interface Props {
  name: string,
  email: string,
  phone: string,
  address: string,
  option: string,
  isFrench: boolean
}

const handleSubmit = async ({ name, email, phone, address, option, isFrench }: Props) => {
  event.preventDefault()
  // console.log("handleSubmit")

  // Validate form inputs
  if (name === '' || name.length <= 2) {

    toast.error(isFrench ? 'Veuillez entrer un nom valide de plus de deux caratères.' : 'Please enter a name of more than two characters.', { position: "top-center" })
    return "Error..."
  }

  const emailRegex = /\S+@\S+\.\S+/
  if (email === '' || !emailRegex.test(email)) {
    toast.error(isFrench ? 'Veuillez entrer une addresse courriel valide.' : 'Please enter a valid email address.', { position: "top-center" })
    return "Error..."
  }

  if (phone === '' || phone.length < 10) {
    toast.error(isFrench ? 'Veuillez entrer un numéro de téléphone valide de plus de 10 caratères.' : 'Please enter a phone number of more than 10 characters', { position: "top-center" })
    return "Error..."
  }

  if (address.length <= 5) {
    toast.error(isFrench ? 'Veuillez entrer une addresse de propriété valide de plus de deux cinq caractères.' : 'Please enter a property address of more than 5 characters.', { position: "top-center" })
    return "Error..."
  }

  if (option === '') {
    toast.error(isFrench ? 'Veuillez choisir une option de vente.' : 'Please choose a selling option.', { position: "top-center" })
    return "Error..."
  }

  // Set up EmailJS variables
  const serviceId = config.emailJs.serviceId
  const templateId = config.emailJs.templateId
  const userId = config.emailJs.userId

  // Send email function
  const sendEmail = ({
    name,
    email,
    phone,
    address,
    option
  }) => {
    emailjs
      .send(
        serviceId,
        templateId,
        {
          name,
          email,
          phone,
          address,
          option
        },
        userId
      )
      .then(response => {
        if (response.status === 200) {
          toast.success(isFrench ? "Merci d'avoir soumis une estimation ! Nous vous contacterons dans les plus brefs délais. Bonne journée !" : "Thank you for your submission! We'll contact you as soon as possible.", { position: "top-center" })
        }
      })
      // Handle errors here however you like
      .catch(err => {
        toast.error(isFrench ? "Une erreur est survenue. Veuillez réessayer plus tard." : "An error occured. Please try again later.")
        console.error("Failed to send estimate. Error: ", err)
        return "Error..."
      })
  }

  // Call the sendEmail function
  sendEmail({
    name,
    email,
    phone,
    address,
    option
  })
  // Write form to database
  const firebase = getFirebase()
  if (firebase) {
    try {
      const db = firebase.firestore()
      await db.collection("twentyTwo").add({
        name,
        email,
        phone,
        address,
        option,
        date: Date(),
      })
      // console.log("Successfully added to Firestore")
      return "Success!"
    } catch (error) {
      console.log(`Error while writing to database`, error)
      return "Error..."
    }

  }
}

export default handleSubmit