import React, { ReactNode, createContext, useReducer, useContext } from 'react'

import languageReducer, { Actions } from './languageReducer'

type ContextProps = {
  children: ReactNode
}

type ContextValue = {
  language: string,
  setEnglish: () => void
  setFrench: () => void
}

const LanguageContext = createContext<ContextValue | undefined >(undefined)

export function LanguageProvider({children}: ContextProps) {
  const [state, dispatch] = useReducer(languageReducer, { language: 'fr'})

  const setEnglish = () => {
    dispatch({
      type: Actions.setLanguage,
      payload: {
        language: 'en'
      }
    })
  }

  const setFrench = () => {
   dispatch({
      type: Actions.setLanguage,
      payload: {
        language: 'fr'
      }
    })
  }

 
  const value = {
    language: state.language,
    setEnglish,
    setFrench,
  }


  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

const useLanguage = () => {
  const context = useContext(LanguageContext)

  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider.')
  }

  return context
}

export default useLanguage