import { useState } from 'react'
import config from '../config'

const useAddressAutocompelte = (initialValue: string) => {
  const [value, setValue] = useState(initialValue)
  const [suggestions, setSuggestions] = useState([])



  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)

    try {
      if (event.target.value === "") {
        setSuggestions([])
      } else {
        const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?country=CA&proximity=45.5017,73.5673&access_token=${config.mapbox.apiKey}&autocomplete=true`;
        const response = await fetch(endpoint);
        const results = await response.json();
        setSuggestions(results?.features);
      }
    } catch (error) {
      console.log("Error fetching address autocomplete, ", error);

    }
  }

  return {
    value,
    onChange: handleChange,
    bind: {
      value,
      onChange: handleChange
    },
    setValue,
    suggestions,
    setSuggestions
  }
}

export default useAddressAutocompelte