interface Config {
  firebase: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
  },
  emailJs: {
    serviceId: string
    templateId: string
    userId: string
  },
  mapbox: {
    apiKey: string
  }
}

const config: Config = {
  firebase: {
    apiKey: process.env.GATSBY_FIREBASE_APIKEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
  },
  emailJs: {
    serviceId: process.env.GATSBY_EMAILJS_SERVICE_ID,
    templateId: process.env.GATSBY_EMAILJS_TEMPLATE_ID,
    userId: process.env.GATSBY_EMAILJS_USERID
  },
  mapbox: {
    apiKey: process.env.GATSBY_MAPBOX_API_KEY
  }
}

// console.log('config', config)

export default config

